<template>
	<div v-if="show">
		<pre-live-embed-code
      :event="event"
      :id="'preLiveEmbedCode'"
      :eventSettings="eventSettings"
      :isFromRegister="isFromRegister">
    </pre-live-embed-code>

    <h2 :style="colorPage" class="text-xl lg:text-4xl mt-8">{{ event.name }}</h2>

    <div
			class="event-description ml-1 mt-4"
      :style="colorPage"
			v-html="cleanHTML(event.description)">
		</div>

    <attachment-list
      :style="colorPage"
			:attachments="event.attachments"
			:eventSettings="eventSettings">
		</attachment-list>

    <div class="py-4 mt-2 space-y-3 ml-1" :style="colorPage">
      <!-- Location -->
      <div v-if="event && event.location && isShowLocation" class="text-md md:flex">
        <div class="">{{ $t('Location') }}:</div>
        <div class="md:ml-1 font-semibold">{{ event && event.location }}</div>
      </div>

      <!-- Date -->
      <div v-if="isShowDateTime" class="text-md md:flex">
        <div class="">{{ $t('Date & Time') }}:</div>
        <div class="md:ml-1 font-semibold">{{ formatEventTime }}</div>
      </div>

      <!-- Notes -->
      <div v-if="event && event.notes" class="text-md md:flex">
        <div class="">{{ $t('Notes') }}:</div>
        <div class="md:ml-1 font-semibold">{{ event && event.notes ? event.notes : '-' }}</div>
      </div>

      <!-- Event will start in -->
      <div class="text-md md:flex" v-if="time && isShowCountdown">
        <div class="">{{ $t('Event will start in') }}:</div>
        <div class="md:ml-1 font-semibold">
          <countdown :time="time" @progress="handleCountdownProgress" v-if="time">
            <template slot-scope="props">
                {{ props.time }}
                <div v-if="props.days > 0 || props.hours > 0 || props.minutes > 0 || props.seconds > 0">
                  <div class="flex text-center">
                    <div v-if="props.days > 0">
                      <div class="">{{ props.days }} <span class="">{{ $t('Days') }}</span></div>
                    </div>
                    <div v-if="props.hours > 0">
                      <div class="" :class="{'ml-2': props.days > 0}">{{ props.hours }} <span class="">{{ $t('Hours') }}</span></div>
                    </div>
                    <div v-if="props.minutes > 0">
                      <div class="" :class="{'ml-2': props.hours > 0 || props.days > 0}">{{ props.minutes }} <span class="">{{ $t('Minutes') }}</span></div>
                    </div>
                    <div v-if="props.seconds > 0 || props.minutes > 0">
                      <div class="block hidden md:block" :class="{'ml-2': props.hours > 0 || props.days > 0 || props.minutes > 0}">{{ props.seconds }} <span class="">{{ $t('Seconds') }} </span></div>
                    </div>
                </div>
              </div>
            </template>
          </countdown>
        </div>
      </div>
      <div v-if="!time">
        <div class="ledContainer">
          <i class="recordLed live"></i><span class="liveText">&nbsp; {{ $t('LIVE') }}</span>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
// import dayjs from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PreLiveEmbedCode from '@/components/events/PreLiveEmbedCode.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import noLocale from '@/lib/locale/no';
import relativeTime from 'dayjs/plugin/relativeTime';
import AttachmentList from '@/components/AttachmentList.vue';
import { mapGetters } from 'vuex';

dayjs.locale('en');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  name: 'EventLive',
  components: {
    PreLiveEmbedCode,
    countdown: VueCountdown,
    AttachmentList,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    date: {
      type: String,
      default: () => null,
    },
    timeZone: {
      type: String,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
    event: {
      type: Object,
      default: () => null,
    },
    isFromRegister: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      time: 0,
    };
  },
  watch: {
    date() {
      this.startCount();
    },
    isEnabledCountdown() {
      this.startCount();
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    fontColor() {
      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_color;
      if (!color) return '';
      const styles = {
        color: `${color}`,
      };
      return styles;
    },
    userLang() {
      const lang = this.user && this.user.language ? this.user.language : 'en';
      return lang;
    },
    isCustomStartingMessageEnabled() {
      const isEnabled = this.eventSettings && this.eventSettings.video && this.eventSettings.video.is_custom_label_enabled;
      return isEnabled;
    },
    customStartingMessage() {
      const isEnabled = this.eventSettings && this.eventSettings.video && this.eventSettings.video.starting_label;
      return isEnabled;
    },
    startingMessage() {
      let message = this.$t('Video will start soon');
      if (this.isCustomStartingMessageEnabled) message = this.customStartingMessage;
      return message;
    },
    isEnabledCountdown() {
      const isCountDown = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      return isCountDown && isCountDown.is_enabled_countdown ? isCountDown.is_enabled_countdown : null;
    },
    isShowCountdown() {
      const isCountDown = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      if (!Object.hasOwnProperty.call(this.eventSettings.style, 'is_show_countdown')) return true;
      return isCountDown && isCountDown.is_show_countdown ? isCountDown.is_show_countdown : false;
    },
    isShowDateTime() {
      const isDateTime = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      if (!Object.hasOwnProperty.call(this.eventSettings.style, 'is_show_date_time')) return true;
      return isDateTime && isDateTime.is_show_date_time ? isDateTime.is_show_date_time : false;
    },
    isShowLocation() {
      const isLocation = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : false;
      if (!Object.hasOwnProperty.call(this.eventSettings.style, 'is_show_location')) return true;
      return isLocation && isLocation.is_show_location ? isLocation.is_show_location : false;
    },
    formatEventTime() {
      if ((this.userLang && this.userLang === 'no') || (this.event && this.event.language === 'no')) dayjs.locale(noLocale);
      else dayjs.locale('en');

      const initTime = this.event && this.event.start_date ? this.localizeTz(this.event.start_date, this.timeZone) : '';

      let timeLabel = initTime ? dayjs(initTime).format('dddd DD MMMM YYYY') : '';
      if ((this.userLang && this.userLang === 'no') || (this.event && this.event.language === 'no')) timeLabel = initTime ? dayjs(initTime).format('dddd, D. MMMM YYYY') : '';
      return timeLabel;
    },
    fontHeadingColor() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_color ? this.eventSettings.style.font_color : null;
      const fontHeadingColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_heading_color ? this.eventSettings.style.font_heading_color : fontColor;
      if (!fontHeadingColor) return '';
      const styles = {
        color: `${fontHeadingColor}`,
      };
      return styles;
    },
    fontFormat() {
      const format = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_remove_font_style;
      return format;
    },
    colorPage() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color_event ? this.eventSettings.style.is_dark_color_event : false;
      return `color: ${fontColor ? 'white' : 'black'}`;
    },
  },
  methods: {
    cleanHTML() {
      // Clone the description content
      let cleanedDescription = this.event.description;

      // Create a temporary element
      const tempElement = document.createElement('div');
      tempElement.innerHTML = cleanedDescription;
      // Add list-style-type: decimal to ul and ol elements
      const ulElements = tempElement.querySelectorAll('ul');
      const olElements = tempElement.querySelectorAll('ol');

      // eslint-disable-next-line no-return-assign
      ulElements.forEach(el => el.style.listStyleType = 'disc');
      // eslint-disable-next-line no-return-assign
      olElements.forEach(el => el.style.listStyleType = 'decimal');
      if (this.fontFormat) {
        // Remove font-related attributes and inline styles
        const elementsWithFontStyles = tempElement.querySelectorAll('*[style*="font"], *[style*="Font"], *[style*="FONT"]');
        elementsWithFontStyles.forEach((element) => {
          element.removeAttribute('style');
        });

        const elementsWithFontAttributes = tempElement.querySelectorAll('*[font-family], *[font-size], *[font-weight], *[font-style], *[font-color]');
        elementsWithFontAttributes.forEach((element) => {
          element.removeAttribute('font-family');
          element.removeAttribute('font-size');
          element.removeAttribute('font-weight');
          element.removeAttribute('font-style');
          element.removeAttribute('font-color');
        });

        // Get the cleaned HTML content
        cleanedDescription = tempElement.innerHTML;
        cleanedDescription = cleanedDescription.replace(/<a\s+href="([^"]+)">/g, '<a href="$1" target="_blank">');

        return cleanedDescription;
      }
      cleanedDescription = tempElement.innerHTML;
      this.event.description = cleanedDescription.replace(/<a\s+href="([^"]+)">/g, '<a href="$1" target="_blank">');
      return this.event.description;
    },
    localizeTz(t, tz) {
      const osloDateString = t.slice(0, -1); // Assuming this is in Oslo time
      const osloTime = dayjs.tz(osloDateString, tz);
      const utcTime = osloTime.tz(dayjs.tz.guess());
      return utcTime.format();
    },
    startCount() {
      // Now
      const now = new Date();
      // Convert
      const inputTimestamp = (this.localizeTz(this.date, this.timeZone));
      const formattedTimestamp = inputTimestamp.slice(0, 19).replace('T', ' ');
      const newDate = new Date(formattedTimestamp);
      const time = newDate - now;
      if (time > 0) {
        this.time = time;
        this.showCountdown(true);
      } else {
        this.showCountdown(false);
      }
    },
    handleCountdownProgress(data) {
      if (data.totalSeconds === 0) {
        this.time = null;
        this.showCountdown(false);
      }
    },
    showCountdown(toggle) {
      this.$emit('showCountdown', toggle);
    },
  },
  mounted() {
  },
  created() {
    this.startCount();
  },
  destroyed() {
  },
};
</script>

<style lang="scss" scoped>
.timer-wrapper {
    margin: 0 auto;
    background-color: #fff;
	.time-header {
		font-size: 1.5em;
		color: #6F6F6F;
	}
	.time-item {
		width: 100%;
		border-right: 1px solid #6F6F6F;
		&:last-child {
			border-right: 0;
		}
		.time-counting {
			font-size: 3em;
			font-weight: bold;
			color: #EC2B34;
		}
		.time-info {
			font-size: 1.2em;
			text-transform: uppercase;
			color: #6F6F6F;
		}
	}
}

.ledContainer {
	color: #666;
	display: flex;
	width: 380px;
  align-items: center;
}

.recordLed {
	width: 20px;
	height: 20px;
	background-color: red;
	border-radius: 50%;
}

.liveText {
	font-size: 20px;
}

.live {
  animation: blinking 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

@keyframes blinking {
  from { opacity: 1; }
  to { opacity: 0; }
}
</style>
