<template>
	<div>
		<!-- Description -->
		<div
    :style="removeFontStyles"
			class="event-description text-red-600"
			v-html="cleanHTML(event.description)">
		</div>


		<!-- Button show more -->
		<!-- <div
			v-if="event.description && event.description.length > 500"
			class="text-right pointer text-blue"
			:style="fontColor"
			@click="toggleShowMore()">
			{{ !isShowMore ? $t('Read more') : $t('Read less') }}
		</div> -->

		<!-- Attachments -->
		<attachment-list
			:attachments="event.attachments"
			:eventSettings="eventSettings">
		</attachment-list>
	</div>
</template>

<script>

import AttachmentList from '@/components/AttachmentList.vue';

export default {
  name: 'LiveEventDetails',
  components: {
    AttachmentList,
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      removeFontStyles: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        fontStyle: 'inherit',
        // Add more CSS properties as needed
      },
      isShowMore: false,
    };
  },
  computed: {
    fontColor() {
      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_color;
      if (!color) return '';
      const styles = {
        color: `${color}`,
      };
      return styles;
    },
    fontFormat() {
      const format = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_remove_font_style;
      return format;
    },
  },
  methods: {
    cleanHTML() {
      // Clone the description content
      let cleanedDescription = this.event.description;

      // Create a temporary element
      const tempElement = document.createElement('div');
      tempElement.innerHTML = cleanedDescription;
      // Add list-style-type: decimal to ul and ol elements
      const ulElements = tempElement.querySelectorAll('ul');
      const olElements = tempElement.querySelectorAll('ol');

      // eslint-disable-next-line no-return-assign
      ulElements.forEach(el => el.style.listStyleType = 'disc');
      // eslint-disable-next-line no-return-assign
      olElements.forEach(el => el.style.listStyleType = 'decimal');
      if (this.fontFormat) {
        // Remove font-related attributes and inline styles
        const elementsWithFontStyles = tempElement.querySelectorAll('*[style*="font"], *[style*="Font"], *[style*="FONT"]');
        elementsWithFontStyles.forEach((element) => {
          element.removeAttribute('style');
        });

        const elementsWithFontAttributes = tempElement.querySelectorAll('*[font-family], *[font-size], *[font-weight], *[font-style], *[font-color]');
        elementsWithFontAttributes.forEach((element) => {
          element.removeAttribute('font-family');
          element.removeAttribute('font-size');
          element.removeAttribute('font-weight');
          element.removeAttribute('font-style');
          element.removeAttribute('font-color');
        });

        // Get the cleaned HTML content
        cleanedDescription = tempElement.innerHTML;
        cleanedDescription = cleanedDescription.replace(/<a\s+href="([^"]+)">/g, '<a href="$1" target="_blank">');

        return cleanedDescription;
      }
      cleanedDescription = tempElement.innerHTML;
      this.event.description = cleanedDescription.replace(/<a\s+href="([^"]+)">/g, '<a href="$1" target="_blank">');
      return this.event.description;
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
