<template>
	<modal
		class="modal-view-question"
		size="small-medium"
		:hide-footer="true"
		:show-close="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<div class="flex items-center">
				<h5 class="modal-title">
					{{ $t('Question') }}
					<span v-if="question">- {{ $t("Details") }}</span>
				</h5>
			</div>
		</div>
		<div slot="body">
			<div v-if="question">
				<!-- Item -->
				<question-item
					:question="question"
					:is-show-first-reply="false"
					:is-show-total-reply="false"
					:is-show-action="false"
					:is-show-list-replies="true"
					:is-show-action-details="false"
				/>
			</div>
		</div>
	</modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import QuestionItem from '@/components/questions/QuestionItem.vue';

export default {
  components: {
    Modal,
    QuestionItem,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  watch: {
    show() {
      if (!this.show) {
        document.body.classList.remove('modal-frontlined');
      } else {
        document.body.classList.add('modal-frontlined');
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
