<template>
	<div>
		<div class="text-lg font-bold mb-2">
			{{ $t('Active Poll') }}
		</div>
		<!-- Polling box -->
		<polling-box
			:event="event"
			:participant="participant"
			:eventSettings="eventSettings"
			>
		</polling-box>
	</div>
</template>

<script>
import PollingBox from '@/components/polls/PollingBox.vue';

export default {
  components: {
    PollingBox,
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
