<template>
	<div class="w-full">
    <div class="question-list-wrapper" :style="isFullscreen ? 'height: 100vh;': ''">
      <!-- Add question box -->
      <add-question-box
        :is-collapseable="true"
        :event="event"
        :eventSettings="eventSettings"
        @setFilterTab="setFilterTab"
        @isQuestionCollapse="checkCollapse"
        v-if="!isQuestionClosed"
      />

      <!-- Questions -->
      <div v-if="totalQuestions > 0">
        <!-- Filter -->
        <div v-if="!isQuestionOnlyRecent">
          <div class="flex items-center justify-between  filter-tabs" :style="colorPage">
            <div class="flex space-x-4 pl-1">
              <div
                class="filter-tab"
                v-tooltip="$t('Show most popular question first')"
                :class="`${orderBy === 'likes' ? 'selected' : ''}`"
                @click="orderBy = 'likes'">
                {{ $t("Popular") }}
              </div>
              <div
                class="filter-tab"
                v-tooltip="$t('Show the latest question first')"
                :class="`${orderBy === 'created_at' ? 'selected' : ''}`"
                @click="orderBy = 'created_at'">
                {{ $t("Recent") }}
              </div>
            </div>

            <div class="text-sm total-question">
              {{ totalQuestions }} {{ $t("Questions") }}
            </div>
          </div>
        </div>

        <!-- List -->
        <div class="question-list" :class="{'mt-4': isQuestionOnlyRecent && !isQuestionClosed}">
          <div
            v-for="(question, index) in filteredQuestions" :key="index"
            class="question-list-item"
            >
            <!-- Item -->
            <question-item
              :question="question"
              :is-show-first-reply="true"
              :is-show-total-like="true"
              :eventSettings="eventSettings"
              @onLike="onLikeQuestion"
              @showEdit="showEdit"
              @showWarningEdit="showWarningEdit"
              @showReply="showReply"
              @viewSelected="showViewQuestion"
              @showWithdrawQuestion="showWithdrawQuestion"
            />
          </div>
        </div>
      </div>

      <!-- If no questions -->
      <div class="question-list__empty" v-if="totalQuestions === 0 && !isFetching && !isQuestionClosed">
        {{ $t("No question yet, be the first to ask") }}
      </div>

      <!-- Fetching -->
      <div class="py-10 text-center" v-if="isFetching && totalQuestions === 0">
        <div class="is-loading large dark"></div>
      </div>

      <!-- Add question -->
      <div
        v-if="!isQuestionClosed"
        class="add-question-wrapper">
        <div
          class="flex space-x-2 items-center text-white add-question-button"
          @click="showAddQuestion()"
          >
          <vs-icon class="add-question-icon" color="#FFF">question_answer</vs-icon>
          <span class="uppercase">
            {{ $t("Ask") }}
          </span>
        </div>
      </div>
    </div>

		<!-- Modal - Add question -->
		<modal-add-question
			:show="isShowAdd"
      :event="event"
      :selected="selected"
      :parentQuestion="selectedParent"
      :eventSettings="eventSettings"
			@close="closeAddQuestion()"
		/>

    <ModalReplyQuestion
      :isShowReplies="isShowReplies"
      @closeReplies="closeAddQuestion()"
      :selectedItem="selectedParent"
      :isFromLive="true"
      :eventSettings="eventSettings"
    ></ModalReplyQuestion>

    <!-- Modal - View question -->
		<modal-view-question
      :event="event"
      :show="isShowViewQuestion"
      :question="selected"
      @close="closeViewQuestion()"
      ref="modalViewQuestion"
    />

    <!-- Modal - Warning Edit Question -->
		<vs-popup
      classContent="edit-warning-question"
      :title="$t('Edit Question')"
      :active.sync="isShowWarningEdit">
      {{ $t("You can't edit your question anymore but you can withdraw and resend it.") }}
      <vs-divider/>
      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-button color="primary" type="filled" @click="closeWarningEdit()">{{$t('Got It')}}</vs-button>
      </vs-row>
    </vs-popup>

    <!-- Modal - Withdraw -->
		<vs-popup
			classContent="withdraw-question"
			:title="$t('Withdraw Question')"
			:active.sync="isShowWithdrawQuestion">
			{{ $t("Your question will be withdrawn from the Q&A and there's no way back.") }}
			<vs-divider/>
			<vs-row vs-type="flex" vs-justify="flex-end">
				<vs-button :disabled="isDeleting" color="#B8B8B8" class="mr-3" type="filled" @click="closeWithdrawQuestion()">{{$t('Cancel')}}</vs-button>
				<vs-button :class="{ 'is-loading': isDeleting }" color="primary" type="filled" @click="approveWithdrawQuestion()">{{$t('Confirm')}}</vs-button>
			</vs-row>
		</vs-popup>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import questionApi from '@/api/question';
import { duplicateVar } from '@/lib/helper';
import QuestionItem from '@/components/questions/QuestionItem.vue';
import AddQuestionBox from '@/components/questions/AddQuestionBox.vue';
import ModalAddQuestion from '@/components/questions/ModalAddQuestion.vue';
import ModalViewQuestion from '@/components/events/questions/ModalViewQuestion.vue';
import ModalReplyQuestion from '@/components/qna/ModalReplyQuestion.vue';


export default {
  components: {
    QuestionItem,
    AddQuestionBox,
    ModalAddQuestion,
    ModalViewQuestion,
    ModalReplyQuestion,
  },
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    participant: {
      type: Object,
      default: () => null,
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      questions: [],
      isShowAdd: false,
      page: 1,
      limit: 9999,
      orderBy: 'likes',
      sortBy: 'desc',
      isFetching: false,
      selected: null,
      selectedParent: null,
      isShowViewQuestion: false,
      isShowWarningEdit: false,
      isShowWithdrawQuestion: false,
      isDeleting: false,
      isQuestionCollapse: false,
      isShowReplies: false,
    };
  },
  sockets: {
    questions_add(question) {
      this.onAddQuestion(question);
    },
    questions_update(question) {
      this.onUpdateQuestion(question);
    },
    questions_delete(question) {
      this.onDeleteQuestion(question);
    },
    questions_premade(question) {
      this.onUpdateQuestion(question);
    },
  },
  watch: {
    orderBy() {
      if (this.orderBy === 'likes') this.sortBy = 'desc';
      else if (this.orderBy === 'created_at') {
        this.sortBy = 'desc';
      }

      this.fetchList();
    },
    eventSettings() {
      this.changeHeight();
    },
    isQuestionModerated() {
      if (!this.isQuestionModerated) this.fetchList();
    },
    isQuestionOnlyRecent() {
      this.fetchList(true);
    },
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    totalQuestions() {
      return this.filteredQuestions.length;
    },
    filteredQuestions() {
      let filtered = this.questions.filter((curr) => {
        const isPublished = curr.is_published && !curr.is_archived;
        let isMyQuestion = true;
        if (this.isQuestionModerated) {
          if (!this.isMyQuestion(curr)) isMyQuestion = false;
        }

        const isValid = (isPublished || isMyQuestion) && !curr.is_premade;
        return isValid;
      });

      // Filter by likes
      if (this.orderBy === 'likes') {
        filtered = filtered.sort((a, b) => {
          const aIdentityNumber = a.likes !== null && a.likes !== '' ? a.likes : 999999;
          const bIdentityNumber = b.likes !== null && b.likes !== '' ? b.likes : 999999;
          const sort = parseFloat(bIdentityNumber) - parseFloat(aIdentityNumber);
          return sort;
        });
      }

      return filtered;
    },
    isQuestionModerated() {
      let isModerated = false;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings && qnaSettings.is_moderated) isModerated = true;
      return isModerated;
    },
    isQuestionClosed() {
      let isClosed = false;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings && qnaSettings.is_closed) isClosed = true;
      return isClosed;
    },
    isQuestionOnlyRecent() {
      let recentOnly = false;
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : null;
      if (qnaSettings && qnaSettings.is_only_recent) recentOnly = true;
      return recentOnly;
    },
    isInteractiveMode() {
      const currentURL = window.location.href;
      const url = new URL(currentURL);
      const isInteractiveValid = url.searchParams.get('is_interactive') === '1' || false;
      return isInteractiveValid;
    },
    isPollsVisible() {
      const pollsSettings = this.eventSettings && this.eventSettings.polls ? this.eventSettings.polls : null;
      return pollsSettings.is_enabled;
    },
    isQuestionPaneVisible() {
      const qnaSettings = this.eventSettings && this.eventSettings.question_answer ? this.eventSettings.question_answer : false;
      return qnaSettings.is_enabled;
    },
    isFullscreen() {
      const styleSettings = this.eventSettings && this.eventSettings.style ? this.eventSettings.style : null;
      const isFullscreen = styleSettings && styleSettings.is_fullscreen;
      return isFullscreen;
    },
    colorPage() {
      const fontColor = this.eventSettings && this.eventSettings.style && this.eventSettings.style.is_dark_color_event ? this.eventSettings.style.is_dark_color_event : false;
      return `color: ${fontColor ? 'white' : 'black'}`;
    },
  },
  methods: {
    showAddQuestion() {
      this.isShowAdd = true;
    },
    closeAddQuestion() {
      this.isShowAdd = false;
      this.isShowReplies = false;
    },
    checkCollapse(status) {
      this.isQuestionCollapse = status;
      this.changeHeight();
    },
    fetchList(isReset = false) {
      if (!this.event) return;
      if (isReset) {
        this.page = 1;
        this.questions = [];
      }
      const params = {
        page: this.page,
        limit: this.limit,
        order_by: this.orderBy,
        sort_by: this.sortBy,
        is_mine: false,
      };
      if (this.isQuestionOnlyRecent) params.order_by = 'created_at';
      this.isFetching = true;
      const callback = (response) => {
        this.isFetching = false;
        const data = response.data;
        this.questions = data;
        this.initHeight();
      };
      const errorCallback = () => {
        this.isFetching = false;
      };
      questionApi.list(this.event.id, params, callback, errorCallback);
    },
    isMyQuestion(question) {
      const isValid = this.user && question && question.participant_id === this.user.id;
      return isValid;
    },
    onLikeQuestion(question) {
      this.onUpdateQuestion(question);
    },
    onAddQuestion(question) {
      console.log(question, this.isQuestionModerated, !question.is_published, !this.isMyQuestion(question));
      if (this.isQuestionModerated && !question.is_published && !this.isMyQuestion(question)) return;
      if (question && question.parent_id) {
        const currentQuestion = this.questions.find(({ id }) => id === question.parent_id);
        if (currentQuestion) {
          const replyQuestion = currentQuestion.replies.find(curr => curr.id === question.id);
          if (!replyQuestion) currentQuestion.replies.push(question);
        }
      } else if (this.orderBy === 'likes') {
        this.questions.push(question);
      } else {
        this.questions.unshift(question);
      }
      this.$emit('getTotalQuestion', this.totalQuestions);
    },
    onUpdateQuestion(question) {
      console.log('update', question);
      if (question && question.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === question.parent_id);
        const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === question.id);
        this.$set(this.questions[index].replies, indexReplies, question);
        if (indexReplies === -1) {
          this.questions[index].replies.push(question);
        }
      } else {
        const index = this.questions.findIndex(({ id }) => id === question.id);
        this.$set(this.questions, index, question);

        if (index === -1) {
          this.onAddQuestion(question);
        }
      }
    },
    onDeleteQuestion(question) {
      if (question && question.parent_id) {
        const index = this.questions.findIndex(({ id }) => id === question.parent_id);
        const indexReplies = this.questions[index].replies.findIndex(({ id }) => id === question.id);
        this.questions[index].replies.splice(indexReplies, 1);
      } else {
        const index = this.questions.findIndex(({ id }) => id === question.id);
        this.questions.splice(index, 1);
        this.$emit('getTotalQuestion', this.totalQuestions);
      }
    },
    showEdit(question) {
      this.selected = duplicateVar(question);
      this.isShowAdd = true;
    },
    closeEdit() {
      this.isShowAdd = false;
      this.selected = null;
    },
    showReply(question) {
      this.selectedParent = duplicateVar(question);
      this.isShowReplies = true;
    },
    closeReply() {
      this.isShowAdd = false;
      this.selectedParent = null;
    },
    showViewQuestion(question) {
      this.selected = duplicateVar(question);
      this.isShowViewQuestion = true;
    },
    closeViewQuestion() {
      this.isShowViewQuestion = false;
      this.selected = null;
    },
    initHeight() {
      this.onResizePage();
      this.changeHeightOnLoad();
    },
    changeHeightOnLoad() {
      setTimeout(() => {
        this.changeHeight();
      }, 200);
    },
    onResizePage() {
      // eslint-disable-next-line
      $(() => {
        // eslint-disable-next-line
        $(window).resize(() => {
          this.changeHeight();
        });
      });
    },
    changeHeight() {
      // eslint-disable-next-line
      const height = $(window).height();
      let heightWrapper = this.isQuestionClosed ? 260 : 320;
      if (this.isFullscreen) {
        heightWrapper -= 60;
      }

      // make more adapative height
      if (window.innerWidth > 1199) {
        if (this.isQuestionCollapse) heightWrapper += 150;
        // eslint-disable-next-line
        $('.question-list').css({
          'max-height': height - heightWrapper,
          'overflow-y': 'auto',
        });
      } else {
        if (this.isInteractiveMode) {
          let totalHeight = 0;
          if (!this.isQuestionClosed) totalHeight += 100;
          if (!this.isQuestionOnlyRecent) totalHeight += 80;
          if (this.isQuestionPaneVisible && this.isPollsVisible) totalHeight += 130;
          if (this.isQuestionPaneVisible && this.isPollsVisible && !this.isQuestionOnlyRecent) totalHeight = 180;
          if (this.isQuestionPaneVisible && this.isPollsVisible && !this.isQuestionClosed) totalHeight = 190;
          if (this.isQuestionPaneVisible && this.isQuestionOnlyRecent) totalHeight = 150;
          if (this.isQuestionPaneVisible && this.isPollsVisible && !this.isQuestionOnlyRecent && !this.isQuestionClosed) totalHeight = 220;
          if (this.isQuestionPaneVisible && this.isPollsVisible && this.isQuestionOnlyRecent && !this.isQuestionClosed) totalHeight = 190;
          if (this.isQuestionPaneVisible && !this.isPollsVisible && !this.isQuestionOnlyRecent && this.isQuestionClosed) totalHeight = 130;
          if (this.isQuestionPaneVisible && !this.isPollsVisible && this.isQuestionOnlyRecent && this.isQuestionClosed) totalHeight = 90;
          if (this.isQuestionCollapse) totalHeight += 150;
          // eslint-disable-next-line
          $('.question-list').css({
            'max-height': `calc(100vh - ${totalHeight}px)`,
            'overflow-y': 'auto',
          });
        }
        if (!this.isInteractiveMode) {
          let totalHeight = 0;
          if (!this.isQuestionOnlyRecent) totalHeight += 180;
          if (!this.isQuestionClosed) totalHeight += 190;
          if (!this.isQuestionOnlyRecent && !this.isQuestionClosed) totalHeight = 220;
          if (this.isQuestionCollapse) totalHeight += 150;
          // eslint-disable-next-line
          $('.question-list').css({
            'max-height': `calc(100vh - ${totalHeight}px)`,
            'overflow-y': 'auto',
          });
        }
      }
    },
    showWarningEdit() {
      this.isShowWarningEdit = true;
    },
    closeWarningEdit() {
      this.isShowWarningEdit = false;
    },
    setFilterTab(tab) {
      this.$emit('setFilterTab', tab);
    },
    showWithdrawQuestion(question) {
      this.selected = duplicateVar(question);
      this.isShowWithdrawQuestion = true;
    },
    closeWithdrawQuestion() {
      this.isShowWithdrawQuestion = false;
      this.selected = null;
    },
    approveWithdrawQuestion() {
      if (!this.selected) return;

      this.isDeleting = true;
      const callback = () => {
        this.isDeleting = false;
        this.closeWithdrawQuestion();
      };
      const errorCallback = () => {
        this.isDeleting = false;
        this.isModalActiveLocal = false;
      };
      questionApi.delete(this.selected.id, callback, errorCallback);
    },
  },
  mounted() {
    this.fetchList();
  },
  created() {
  },
  destroyed() {
  },
};
</script>
