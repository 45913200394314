<template>
	<div
    v-if="event && event.embed_code"
    class="live-embed-code"
    :id="id"
    ref="liveEmbedCode">
    <youtube
      v-if="youtubeId"
      id="youtubeComponent"
      :video-id="youtubeId"
      ref="youtube"
      :resize="true"
      :fitParent="true">
    </youtube>
    <div
      v-if="isVideo"
      ref="videoHTML">
      <video width="100%" height="auto" controls style="border-radius: 10px;">
        <source :src="event.embed_code">
        Your browser does not support the video tag.
      </video>
    </div>
    <div
      v-else
      ref="videoHTML"
      v-html="event.embed_code">
    </div>
	</div>
</template>

<script>
import { duplicateVar } from '@/lib/helper';

export default {
  name: 'LiveEmbedCode',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    event: {
      type: Object,
      default: () => null,
    },
    id: {
      type: String,
      default: () => '',
    },
    isFullscreen: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isShowEmbed: false,
      youtubeId: null,
      isVideo: null,
    };
  },
  computed: {
  },
  methods: {
    embedIframe() {
      // Get the reference to the <div> element
      const divElement = window.document.getElementById(this.id);

      // Prepare the HTML code
      const htmlCode = this.event.embed_code ? this.event.embed_code : '';

      // Set the innerHTML property of the <div> element
      divElement.innerHTML = duplicateVar(htmlCode);
    },
    checkIframeSource() {
      if (this.event && !this.event.embed_code) return;
      const iframeString = this.event.embed_code;

      // Parse the HTML string using DOMParser
      const parser = new DOMParser();
      const doc = parser.parseFromString(iframeString, 'text/html');

      // Get the src attribute value of the iframe
      const srcValue = doc.querySelector('iframe').getAttribute('src');

      if (this.isYouTubeUrl(srcValue)) {
        const youtubeId = this.extractYouTubeVideoId(srcValue);
        if (youtubeId) {
          this.youtubeId = null;
        }
      }

      // set border
      const videoContainer = this.$refs.videoHTML;
      // Get the iframe element inside the div
      const iframeElement = videoContainer.querySelector('iframe');
      if (!this.isFullscreen) iframeElement.style.borderRadius = '10px';
    },
    isYouTubeUrl(url) {
      return /^(https?:\/\/)?(www\.)?youtube.com\//.test(url);
    },
    extractYouTubeVideoId(url) {
      // Regular expression pattern to extract video ID from YouTube URL
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

      // Extract video ID using regular expression
      const match = url.match(regex);

      // Check if there is a match and extract the video ID
      const videoId = match ? match[1] : null;
      return videoId;
    },
    isVideoURL(url) {
      const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.flv', '.mkv', '.wmv'];
      const urlExtension = url.split('.').pop();
      if (videoExtensions.includes(`.${urlExtension}`)) {
        return Promise.resolve(true);
      }

      return fetch(url, { method: 'HEAD' })
        .then((response) => {
          const contentType = response.headers.get('Content-Type');
          return contentType && contentType.startsWith('video/');
        })
        .catch(() => false);
    },
    checkVideoUrl() {
      this.isVideoURL(this.event.embed_code).then((isVideo) => {
        this.isVideo = isVideo;
        if (!isVideo) {
          this.checkIframeSource();
        }
      });
    },
  },
  mounted() {
    this.checkVideoUrl();
  },
  created() {
  },
  destroyed() {
  },
};
</script>
